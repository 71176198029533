import { PRODUCTION, STAGING, QAS, DEV, LOCALHOST } from "./constants/env";
// Main App
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const BLOG_URL = process.env.REACT_APP_BLOG_URL;
// Admin Side
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
// Trader Platform
export const BROKER_DOMAIN_URL = process.env.REACT_APP_BROKER_DOMAIN_URL;
export const BROKER_SOCKET_URL = process.env.REACT_APP_BROKER_SOCKET_URL;

// S3 Images URL
export const S3_IMAGES_BASE_URL = process.env.REACT_APP_S3_IMAGES_BASE_URL;
export const IMAGES_BASE_URL = process.env.REACT_APP_IMAGES_BASE_URL;
export const IMAGES_BASE_URL1 = process.env.REACT_APP_IMAGES_BASE_URL1;

// App Setting
export const VERSION = "/v1";
export const STRIPE_KEY =
	"pk_live_51HHt9oFAlmFL3wjZhw8JtsU9rTecFPtBq1fIJhIAF8PfdiOzlE2BLq8gMkLvpAphOSAByShegSkU4m66MlExjOhT00Ksj1Ud3w";
export const RESELLER_IDs = ["mitch@sunhub.com", "info@yellowlite.com"];
// calendly event link
export const CALENDLY_MEETING_LINK = "https://calendly.com/solar-trader/30min";
export const CONSOLE_LOG = false;
export const FB_ID = 3258557697492983;
export const GOOGLE_CLIENT_ID = "72483669082-7t43svhgf5v4hsga9dqcce6ubcgvooqr.apps.googleusercontent.com";
export const SOCIAL_LOGIN_Authorized_DOMAIN = [PRODUCTION, STAGING, QAS, DEV, LOCALHOST];

export const APP_VERSION = "5.0";
