import React from "react";
import { Link } from "react-router-dom";
import { SlideToggle } from "react-slide-toggle";
import { safeContent } from "../../../utils";

function Card(props) {
	const { title, expanded, adClass, type = "default", onToggle, customLink, subTitle, category, search, showChildrenDrawer, cat } = props;
	return "default" === type ? (
		<SlideToggle collapsed={expanded ? false : true}>
			{({ onToggle, setCollapsibleElement, toggleState }) => (
				<div
					className={`card ${adClass}`}
					onClick={() => (showChildrenDrawer && showChildrenDrawer(cat)) || ""}>
					<div
						className="card-header"
						onClick={onToggle}>
						<h2 className="card-title">
							{customLink ? (
								<span
									className={`toggle-button ${toggleState && toggleState.toLowerCase()}`}
									onClick={onToggle}
									style={{ height: "auto" }}>
									<Link to={"/brands"}>{title}</Link>
								</span>
							) : (
								<span
									className={`toggle-button ${toggleState.toLowerCase()}`}
									style={{ height: "auto" }}>
									{search ? `${title} (${category}) ` : title}
									{subTitle && <small className="shipping-date text-muted">{subTitle}</small>}
								</span>
							)}
						</h2>
					</div>
					<div ref={setCollapsibleElement}>{props.children && <div className="card-body">{props.children}</div>}</div>
				</div>
			)}
		</SlideToggle>
	) : "faq" === type ? (
		<div className="card">
			<div
				className="card-header"
				onClick={onToggle} // Trigger toggle function on click
			>
				<h2 className="card-title d-flex justify-content-between">{title}
					<span className="arrow ml-auto"><i className={`fal fa-angle-${expanded ? "up" : "down"} fa-xl`}></i></span>
				</h2>
			</div>
			{/* {expanded && <div className={`card-body ${expanded ? 'h-auto' : 'h-0'}`}>{props.children}</div>} Conditional Rendering */}
			<div className={`card-body ${expanded ? 'expanded' : 'collapsed'}`}>
				{props.children}</div> {/* Conditional Rendering */}
		</div>
	) : (
		<SlideToggle collapsed={!expanded}>
			{({ setCollapsibleElement, toggleState }) => (
				<div className={`acc-item ${adClass}`}>
					<h5>
						<span
							className={`toggle-button ${toggleState.toLowerCase()}`}
							style={{ height: "auto" }}
							onClick={onToggle}>
							{title}
						</span>
					</h5>
					<div ref={setCollapsibleElement}>
						<div className="collapse-wrap">{props.children}</div>
					</div>
				</div>
			)}
		</SlideToggle>
	);
}

export default React.memo(Card);
