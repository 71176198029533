import moment from "moment";
import React from "react";
import { stringCaptilizeAndRemoveMinus } from "../../../utils";
export function getNameAndIdByLastDash(str = "") {
	const parts = str.split("-");
	const lastIndex = parts.length - 1;

	let name = "";
	let id = "";

	if (lastIndex >= 0) {
		if (parts[lastIndex]) {
			name = parts.slice(0, lastIndex).join("-");
			id = parts[lastIndex];
		} else if (lastIndex >= 1) {
			name = parts.slice(0, lastIndex - 1).join("-");
			id = parts[lastIndex - 1];
		}
	}

	return { name, id };
}

export function buildQueryParams(params) {
	let queryParams = "";
	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			const value = params[key];
			if (value && !(Array.isArray(value) && value.length === 0)) {
				const concatenatedValue = Array.isArray(value) ? value.join(",") : value;
				queryParams += encodeURIComponent(key) + "=" + encodeURIComponent(concatenatedValue) + "&";
			}
		}
	}
	queryParams = queryParams.slice(0, -1);
	return queryParams;
}

export function lowercaseObjectStrings(obj) {
	// Get all the keys of the object
	const keys = Object.keys(obj);

	// Loop through all the keys
	keys.forEach((key) => {
		// Check if the value of the key is a string
		if (typeof obj[key] === "string") {
			// If it is, convert it to lowercase
			obj[key] = obj[key].toLowerCase();
		}
	});

	// Return the updated object
	return obj;
}

export const phoneFormatter = (value) => {
	let newValue = value || '';
	if (newValue.length === 12) {
		if (newValue.startsWith("+1")) {
			newValue = newValue.slice(2);
		}
	}
	let output = "";
	newValue.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
		if (g1.length) {
			output = "(";
			output += g1;
			if (g1.length === 3) {
				if (g2.length) {
					output += ")";
					output += " " + g2;
					if (g2.length === 3) {
						if (g3.length) {
							output += "-";
							output += g3;
						}
					}
				}
			}
		}
	});
	return output;
};

export function formatChatTime(isoString) {
	const time = moment(isoString);

	// Format the time using the 'calendar' and 'format' functions
	const formattedTime = time.calendar(null, {
		sameDay: "[Today] LT",
		lastDay: "[Yesterday] LT",
		lastWeek: "MMMM D, LT",
		sameElse: "MMMM D, LT",
		lastYear: "MMMM D, YYYY [at] LT",
	});

	return formattedTime;
}

export function getFirstLettersOfWords(str) {
	if (str === undefined || str === null || str.trim() === "") {
		return "";
	}
	const words = str.split(" ");
	const firstLetters = words.map((word) => word.slice(0, 1).toUpperCase());
	return firstLetters.join("").slice(0, 2);
}
export function linkify(message) {
	if (message) {
		const urlRegex = /(\s|^)((?:https?:\/\/)?(?:www\.)?\S+\.\S+)(\s|$)/g;
		const parts = message.split(urlRegex);
		const elements = parts.map((part, index) => {
			if (part.match(urlRegex)) {
				const url = part.startsWith("http://") || part.startsWith("https://") ? part : `http://${part}`;
				return (
					<a
						key={index}
						href={url}
						target="_blank"
						style={{ color: "blue", textDecoration: "underline" }}
						rel="noopener noreferrer">
						{part}
					</a>
				);
			} else {
				return <span key={index}>{part}</span>;
			}
		});
		return elements;
	}
	return;
}
export function setDecimalPlace(str) {
	if (typeof str === "number") {
		return str.toFixed(3);
	}
	if (typeof str === "string") {
		return parseFloat(str).toFixed(3);
	} else {
		return str;
	}
}

export function getFileExtensionInCapital(fileName) {
	const fileParts = fileName.split(".");
	if (fileParts.length > 1) {
		const extension = fileParts[fileParts.length - 1];
		return extension.toUpperCase();
	}
	return ""; // No extension found
}
export function checkNetwrok() {
	return Boolean(navigator.onLine); // No extension found
}

export function formatBytes(bytes) {
	if (bytes < 1024) {
		return bytes + " B";
	} else if (bytes < 1024 * 1024) {
		return (bytes / 1024).toFixed(2) + " KB";
	} else {
		return (bytes / (1024 * 1024)).toFixed(2) + " MB";
	}
}

export function quantityCalculator(input, wattage, piecesPerPalets, piecesPerContainer) {
	let { mw, panel: piece, pallet, container, quantity } = input;
	if (quantity) {
		return { quantity };
	}
	if (wattage && piecesPerPalets && piecesPerContainer) {
		if (piece) {
			mw = (piece * wattage) / 1000000;
			pallet = piece / piecesPerPalets;
			container = piece / piecesPerContainer;
		} else if (pallet) {
			piece = pallet * piecesPerPalets;
			mw = (piece * wattage) / 1000000;
			container = piece / piecesPerContainer;
		} else if (container) {
			piece = container * piecesPerContainer;
			mw = (piece * wattage) / 1000000;
			pallet = piece / piecesPerPalets;
		} else if (mw) {
			piece = (mw * 1000000) / wattage;
			pallet = piece / piecesPerPalets;
			container = piece / piecesPerContainer;
		}
	}
	return { mw, pallet: Math.round(pallet), container: Math.round(container), panel: Math.round(piece) };
}

export const getListingType = (listing_type) => {
	if ((listing_type || "").toLowerCase() === "panels" || typeof listing_type === "undefined") {
		return "Watt";
	} else {
		return "Unit";
	}
};
export const getListingTypeColSpan = (listing_type, quantity_calculator) => {
	if ((listing_type || "").toLowerCase() === "panels" || typeof listing_type === "undefined") {
		if (quantity_calculator === "fpq") return 5;
		else return 4;
	} else {
		if (quantity_calculator === "fpq") return 2;
		else return 1;
	}
};
export const getOfferQuantityString = (desired_qty, desired_qty_unit) => {
	return stringCaptilizeAndRemoveMinus(`${desired_qty} ${desired_qty_unit || ""}${"s"}`)
		.replace("Mws", "MW")
		.replace("Quantitys", "Units");
};
export function setDecimalPlaceTwo(str) {
	if (typeof str === "number") {
		return str.toFixed(2);
	}
	if (typeof str === "string") {
		return parseFloat(str).toFixed(2);
	} else {
		return str;
	}
}

export const hasDifferences = (key, objects) => {
	if (objects && objects.length <= 1) {
		return false; // If there's only one object or less, there can't be any differences
	}
	if (objects && objects.length > 1) {
		// Check if any value for the specified key differs across the objects
		for (let i = 1; i < objects.length; i++) {
			if (objects[i][key] !== objects[i - 1][key]) {
				return true; // If values are different, return true
			}
		}

		return false; // If all values for the key are the same, return false
	}
};

export const getLowestPrice = (objects) => {
	if (!objects || objects.length === 0) {
		return null; // If there are no objects, return null
	}
	if (objects && objects.length > 1) {
		let lowestPrice = objects && objects[0].sunhub_price; // Initialize lowest price with the price of the first object

		for (let i = 1; i < objects.length; i++) {
			if (objects[i].sunhub_price < lowestPrice) {
				lowestPrice = objects[i].sunhub_price; // Update lowest price if a lower price is found
			}
		}

		return lowestPrice; // Return the lowest price}
	}
};
