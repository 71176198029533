import * as ENV from "../config";

export const DOMAIN_URL = ENV.DOMAIN_URL;
export const API_URL = ENV.API_URL;
export const IMAGES_BASE_URL = ENV.IMAGES_BASE_URL;
export const IMAGES_BASE_URL1 = ENV.IMAGES_BASE_URL1;
export const VERSION = ENV.VERSION;
export const STRIPE_KEY = ENV.STRIPE_KEY;

// client
export const CLIENT_REGISTER = `${ENV.API_URL}${ENV.VERSION}/client/signup`;
export const CLIENT_REGISTER_GOOGLE = `${ENV.API_URL}${ENV.VERSION}/client/oauth/google/callback`;
export const CLIENT_REGISTER_FB = `${ENV.API_URL}${ENV.VERSION}/client/oauth/facebook/callback`;
export const GET_GOOGLE_PROFILE = `https://www.googleapis.com/oauth2/v3/userinfo`;
export const USER_register = `${ENV.API_URL}${ENV.VERSION}/client/oauth/check/user`;
export const CLIENT_SIGNIN = `${ENV.API_URL}${ENV.VERSION}/client/login`;
export const CLIENT_RESEND_VERIFICATION = `${ENV.API_URL}${ENV.VERSION}/client/email/resend`;
export const CLIENT_FORGOT_PASSWORD = `${ENV.API_URL}${ENV.VERSION}/client/password/email`;
export const CLIENT_RESET_PASSWORD = `${ENV.API_URL}${ENV.VERSION}/client/password/reset`;
export const CLIENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/edit`;
export const CLIENT_LOGOUT = `${ENV.API_URL}${ENV.VERSION}/client/logout`;
export const CLIENT_PAYOUT = `${ENV.API_URL}${ENV.VERSION}/client/payout/accounts`;
export const CLIENT_ONBOARDING = `${ENV.API_URL}${ENV.VERSION}/client/payout/accounts/get-onboarding-link/:account_id?return_url=`;

// elastic search
export const SEARCH_CATEGORIES = `${ENV.API_URL}${ENV.VERSION}/client/categories/search`;
export const SEARCH_CATEGORIES_WITH_COUNT = `${ENV.API_URL}${ENV.VERSION}/client/categories/with/products-count`;
export const CATEGORIES_WITH_ORDER_COUNT = `${ENV.API_URL}${ENV.VERSION}/client/custom/order/category/list`;
export const SEARCH_BRANDS = `${ENV.API_URL}${ENV.VERSION}/client/brands/search`;
export const SEARCH_PRODUCT = `${ENV.API_URL}${ENV.VERSION}/client/items/search`;
export const PRODUCTS_DETAIL = `${ENV.API_URL}${ENV.VERSION}/client/items/:uuid`;

// reseller
export const CREATE_RESELLERS = `${ENV.API_URL}${ENV.VERSION}/client/resellers`;
export const SEARCH_RESELLERS = `${ENV.API_URL}${ENV.VERSION}/client/resellers/search`;
export const SEARCH_RESELLERS_LOCATIONS = `${ENV.API_URL}${ENV.VERSION}/client/resellers/:id?with=["locations"]`;
export const DELETE_RESELLERS = `${ENV.API_URL}${ENV.VERSION}/client/resellers/:id/delete`;
export const UPDATE_RESELLERS = `${ENV.API_URL}${ENV.VERSION}/client/resellers/:id`;
export const DOWNLOAD_PRODUCT_FILE = `${ENV.API_URL}${ENV.VERSION}/client/item/inventory/export`;
export const UPLOAD_PRODUCT_FILE = `${ENV.API_URL}${ENV.VERSION}/client/item/inventory/qoh/update`;

// items aka products
export const ADD_PRODUCT = `${ENV.API_URL}${ENV.VERSION}/client/my/items`;
export const EDIT_PRODUCT = `${ENV.API_URL}${ENV.VERSION}/client/my/items/:uuid`;
export const BULK_EDIT_PRODUCT = `${ENV.API_URL}${ENV.VERSION}/client/my/items/bulk/update`;
export const FILE_UPLOAD = `${ENV.API_URL}${ENV.VERSION}/client/my/items/file`;
export const CUSTOM_FILE_UPLOAD = `${ENV.API_URL}${ENV.VERSION}/client/custom/order/file/upload`;
export const FILE_REMOVE = `${ENV.API_URL}${ENV.VERSION}/client/my/items/file/:uuid/delete`; // will drill down best practice for :uuid later
export const ITEM_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/my/items/:uuid/location/:location_id`;
export const ITEM_LOCATION_REMOVE = `${ENV.API_URL}${ENV.VERSION}/client/my/items/:uuid/location/:location_id/delete`;
export const SELLER_PRODUCTS = `${ENV.API_URL}${ENV.VERSION}/client/my/items/search`;
export const SELLER_PRODUCTSITEMS = `${ENV.API_URL}${ENV.VERSION}/client/packages/search`;
export const SNAPSHOT = `${ENV.API_URL}${ENV.VERSION}/client/payout/my-account/snapshot`;
export const ASK_ABOUT_PRODUCTS = `${ENV.API_URL}${ENV.VERSION}/client/items/ask`;
export const PRODUCT_QNA_API_URL = `${ENV.API_URL}${ENV.VERSION}/client/item/discussion/board/create`;
export const GET_PRODUCT_QNA = `${ENV.API_URL}${ENV.VERSION}/client/item/discussion/board/list`;
export const INFORM_PRODUCTS = `${ENV.API_URL}${ENV.VERSION}/client/notify/create`;
export const SOLD_PRODUCTS = `${ENV.API_URL}${ENV.VERSION}/client/my/my/items/:uuid`;
export const CHANGE_PRODUCT_STATUS = `${ENV.API_URL}${ENV.VERSION}/client/my/items/bulk/change-status`;
export const RELATED_PRODUCTS = `${ENV.API_URL}${ENV.VERSION}/client/items/related/:id`;
// Profile
export const ADD_PROFILE_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/addresses`;
export const ADD_PROFILE_ADDRESS = `client/my-profile/addresses`;
// payment
export const ADD_PAYMENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/payment-profiles`;
export const DELETE_PAYMENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/payment-profiles/:id`;
export const UPDATE_PAYMENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/payment-profiles/:id`;
export const LIST_PAYMENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/payment-profiles`;

export const UPDATE_PROFILE_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/addresses/:id`;
export const GET_PROFILE_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/addresses`;
export const GET_PAYMENT_PROFILE = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/payment-profiles`;
export const ADD_REVIEW = `${ENV.API_URL}${ENV.VERSION}/client/items/:uuid/reviews`;
export const FETCH_REVIEW_LIST = `${ENV.API_URL}${ENV.VERSION}/client/items/:uuid/reviews/list`;
export const GET_REVIEW = `${ENV.API_URL}${ENV.VERSION}/client/items/:uuid/reviews/my-list`;
export const PURCHASE_HISTORY = `${ENV.API_URL}${ENV.VERSION}/client/my-profile/purchase-history`;

// locations
export const ADD_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/locations`;
export const DELETE_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/locations/`;
export const UPDATE_LOCATION = `${ENV.API_URL}${ENV.VERSION}/client/locations/`;
export const UPDATE_LOCATIONS = `${ENV.API_URL}${ENV.VERSION}/client/locations/:id`;
export const LOCATIONS = `${ENV.API_URL}${ENV.VERSION}/client/locations/search`;

export const UPDATE_ROLE = `${ENV.API_URL}${ENV.VERSION}/client/change-role`;

// price scaling - bulk saving
export const ADD_PRICE_VARIANT = `${ENV.API_URL}${ENV.VERSION}/client/bulksavings`;
export const DELETE_PRICE_VARIANT = `${ENV.API_URL}${ENV.VERSION}/client/bulksavings/:id`;
export const GET_PRICE_VARIANT = `${ENV.API_URL}${ENV.VERSION}/client/bulksavings/:id`;

//Cart
export const FEDEX_RATE = `${ENV.API_URL}${ENV.VERSION}/client/fedex/rate`;
export const CART_ITEM_LIST = `${ENV.API_URL}${ENV.VERSION}/client/cart/search`;
export const CART_ADD_ITEM = `${ENV.API_URL}${ENV.VERSION}/client/cart`;
export const CHECKOUT = `${ENV.API_URL}${ENV.VERSION}/client/checkout`;
export const CART_REMOVE_ITEM = `${ENV.API_URL}${ENV.VERSION}/client/cart/:uuid/delete`;
export const CART_REMOVE_ITEM_REVAMP = `${ENV.API_URL}${ENV.VERSION}/client/cart/:uuid/delete`;
export const CART_REMOVE_ALL_ITEM = `${ENV.API_URL}${ENV.VERSION}/client/cart/_delete`;
export const SALES_TAX = `${ENV.API_URL}${ENV.VERSION}/client/cart/sales-tax`;
export const SHIPPING_CALCULATOR = `${ENV.API_URL}${ENV.VERSION}/client/shipping/price`;
export const SHIPPING_CALCULATOR_REVAMP = `${ENV.API_URL}${ENV.VERSION}/client/shipping/price`;
export const GET_HIGHEST_QTY = `${ENV.API_URL}${ENV.VERSION}/client/highest/qty/location`;

// Home page
export const PRODUCT_GROUPED_CAT = `${ENV.API_URL}${ENV.VERSION}/client/categories/groupedCatItems?number=6`;
export const CONTACT_US = `${ENV.API_URL}${ENV.VERSION}/client/contact-us`;

// Rv Page
export const RV_SERVICES = `${ENV.API_URL}${ENV.VERSION}/client/rv/surveys`;

//Custom order
export const CUSTOM_ORDERS_SEARCH = `${ENV.API_URL}${ENV.VERSION}/client/custom/order/list`;
//Customer Order
export const CUSTOMER_ORDER = `${ENV.API_URL}${ENV.VERSION}/client/custom/order/create`;

//store front
export const CREATE_UPDATE_STORE = `${ENV.API_URL}${ENV.VERSION}/client/store/front/create`;
export const STORE_FRONT_LISTING = `${ENV.API_URL}${ENV.VERSION}/client/store/front/listing`;
export const GET_STORE = `${ENV.API_URL}${ENV.VERSION}/client/store/front/list`;
export const SERACH_STORE = `${ENV.API_URL}${ENV.VERSION}/client/search/store/front/list`;
export const ADD_PROJECT = `${ENV.API_URL}${ENV.VERSION}/client/work/history/create`;
export const GET_PROJECTS = `${ENV.API_URL}${ENV.VERSION}/client/work/history/list`;
export const GET_PROJECT = GET_PROJECTS;
export const UPDATE_PROJECT = `${ENV.API_URL}${ENV.VERSION}/client/work/history/update`;
export const DELETE_PROJECT = `${ENV.API_URL}${ENV.VERSION}/client/work/history/delete`;
export const CONTACT_SELLER = `${ENV.API_URL}${ENV.VERSION}/client/seller/contact`;
export const GET_SELLER_BRANDS = `${ENV.API_URL}${ENV.VERSION}/client/seller/brand/list`;
// store front favourite
export const STORE_FRONT_FAVOURITE = `${ENV.API_URL}${ENV.VERSION}/client/store/front/favourite`;
export const STORE_FRONT_FAVOURITE_LIST = `${ENV.API_URL}${ENV.VERSION}/client/store/front/favourite/list`;

// store front favourite
export const NEWS_LETTER = `${ENV.API_URL}${ENV.VERSION}/client/news/letter/subscribe`;

//sunhub events
export const SUNHUB_EVENTS = `${ENV.API_URL}${ENV.VERSION}/client/events/search`;

// brand page
// brand page
export const GET_BRANDS = `${ENV.API_URL}${ENV.VERSION}/client/brands/search`;

// google reviews for store front
export const GET_STORE_FRONT_REVIEWS = `https://api.reviewsmaker.com/gmb/?placeid=ChIJQxy-wvv7MIgR80ZwZoOV6KY`;

//order tracking
export const ORDER_STATUS = `${ENV.API_URL}${ENV.VERSION}/client/orders/status`;
// wishlist
export const ADD_WISH_LIST = `${ENV.API_URL}${ENV.VERSION}/client/wishlist`;
export const DELETE_WISH_LIST_BY_ID = `${ENV.API_URL}${ENV.VERSION}/client/wishlist/:uuid/delete`;
export const DELETE_WISH_LIST_ALL = `${ENV.API_URL}${ENV.VERSION}/client/wishlist/_delete  `;
export const WISH_LIST = `${ENV.API_URL}${ENV.VERSION}/client/wishlist/search`;

// buying history
export const BUYING_HISTORY = `${ENV.API_URL}${ENV.VERSION}/client/my/items/seller/sold-items`;
export const SOLD_ITEM_SHIPPED_STATUS = `${ENV.API_URL}${ENV.VERSION}/client/orders/tracking/update`;
export const SOLD_ITEM_INVOICES_PDF = `${ENV.API_URL}${ENV.VERSION}/client/my/items/seller/sold-items/receipt`;

// promotion
export const PROMOTION = `${ENV.API_URL}${ENV.VERSION}/client/promotions`;

// faqs

export const FAQ_CATEGORY = `${ENV.API_URL}${ENV.VERSION}/client/faq-categories/search`;
export const FAQ_SEARCH = `${ENV.API_URL}${ENV.VERSION}/client/faqs/search`;
// featured banner
export const FEATURED_BANNER = `${ENV.API_URL}${ENV.VERSION}/client/banners/search`;
export const Guest_User = `login-info/guest-login`;

// marketing offering landing page
export const MARKET_LANDING_API = `${ENV.API_URL}${ENV.VERSION}/client/ad-marketing-offering`;
