import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { filterStore } from "../../../../api/store-front/filter-store";
import { safeContent } from "../../../../utils";
import { STORE_FRONT_PAGE } from "../../../../constants/routes";
import { IMAGES_BASE_URL } from "../../../../constants/api";
import { Button } from "antd";
import LoginModal from "../../../../pages/broker/common/modals/login";
import { useHistory } from "react-router-dom";
import CartMenuRevamp from "../../../common/partials/cart-menu-revamp";
import { signout } from "../../../../api/auth/signout";
import { SunhubIcon } from "../../../../pages/broker/admin/pages/dasboard/components/icons";

const MegaMenuItems = (props) => {
	const { categories, userData, advertiseButton } = props;
	const [subCategory, setSubCategory] = useState({});
	const [sortedCategories, setSortedCategories] = useState({});
	const [stores, setStores] = useState([]);
	const [isStoreUpdate, setIsStoreUpdate] = useState(false);
	const [loginModalVisible, setIsLoginModalVisible] = useState(false);

	const history = useHistory();
	const isUserSeller = userData.userData.isLoggedIn && userData.userData.data.role && userData.userData.data.role.includes("seller");
	const user = userData.userData.data;
	let role = (userData.userData.isLoggedIn && userData.userData.data.role) || [];

	const isLoggedIn = userData.userData.isLoggedIn;

	const updateCategoryType = (catId) => {
		setSubCategory(sortedCategories[catId]);
	};
	const handleStoreUpdate = () => {
		setIsStoreUpdate(true);
	};

	useEffect(() => {
		filterStore({ users: [24630, 24637, 26109, 26021, 26019, 26020, 26089, 26086] }, (stores) => {
			setStores(stores || []);
		});
	}, [isStoreUpdate]);
	useEffect(() => {
		let indexedCategories = {};
		categories.forEach((category) => {
			indexedCategories = {
				...indexedCategories,
				...{ [category.id]: { ...category } },
			};
		});

		setSortedCategories(indexedCategories);
	}, [categories]);

	const goToSellerCentral = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/my-sunhub/seller-overview/sell-an-item`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/register-business/seller`);
		}
	};
	const goToTraderPlatform = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/trader-deals`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/how-to-sell`);
		}
	};

	const goToTrader = () => {
		history.push(`${process.env.PUBLIC_URL}/solar-trader-platform`);
	};
	const goToMarketing = () => {
		history.push(`${process.env.PUBLIC_URL}/marketing`);
	};

	return (
		<>
			<nav className="navbar navbar-expand-lg ">
				<div className="capsule-navigation">
					<Link
						className="navbar-brand"
						to="#">
						<img
							src={`${process.env.PUBLIC_URL}/assets/images/revamp/logo.svg`}
							alt="sunhub new logo"
						/>
					</Link>
					<div
						className="collapse navbar-collapse"
						id="navbar-content">
						<ul className="navbar-nav ml-auto mr-auto justify-content-center align-items-center">
							{/* Shop -- Mega Menu Navigation Block  */}
							<li className="nav-item dropdown">
								<Link
									onMouseOver={handleStoreUpdate}
									className="nav-link dropdown-toggle"
									to="#"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Buy
								</Link>
								<div
									className="dropdown-menu megaMenuContainer shopMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="row">
											<div className="col-8">
												<div className="p-5">
													<div className="row">
														<div className="col-4">
															<div className="p3">
																<h4 className="mega-menu-section-title">Negotiable</h4>
																<p>Shop our price negotiable solar panels, batteries and inverters.</p>
																<ul className="list-group list-group-flush mt-3">
																	<Link
																		className=""
																		to="/trader/find/pw_300%2c399">
																		<li className="list-group-item list-group-item-action">300W Solar Panels</li>
																	</Link>
																	<Link to="/trader/find/pw_400%2c499">
																		<li className="list-group-item list-group-item-action">400W Solar Panels</li>
																	</Link>
																	<Link to="/trader/find/pw_500">
																		<li className="list-group-item list-group-item-action">500W Solar Panels</li>
																	</Link>
																	<Link to="/trader/find/pw_600">
																		<li className="list-group-item list-group-item-action">600W+ Solar Panels</li>
																	</Link>
																	<Link to="/trader/find/ca_solar-inverters">
																		<li className="list-group-item list-group-item-action">Inverters</li>
																	</Link>
																	<Link to="/trader/find/ca_solar-batteries">
																		<li className="list-group-item list-group-item-action">Batteries</li>
																	</Link>
																	<Link to="/trader/find">
																		<li className="list-group-item list-group-item-action">Shop All Solar Trader Deals</li>
																	</Link>
																</ul>
																<div className="card mt-3">
																	<div className="card-body">
																		<img
																			className="mb-1"
																			src={`${process.env.PUBLIC_URL}/assets/images/revamp/solar-trade-icon.svg`}
																			alt="solar-trader-icon"
																		/>
																		<div className="card-title">Introducing Sunhub’s Solar Trader Platform</div>
																		<div className="card-text">Our ultimate solar equipment trading platform </div>
																		<Link
																			className=""
																			to={`${process.env.PUBLIC_URL}/solar-trader-platform`}>
																			Join for Free <i className="fal fa-long-arrow-right"></i>
																		</Link>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Buy Now</h4>
															<div
																className="list-group list-group-flush"
																id="list-tab"
																role="tablist">
																{categories &&
																	categories.map((cat, index) => {
																		return (
																			<Link
																				to={`${process.env.PUBLIC_URL}/shop/product/${cat.name.replace(/\s+/g, "-").toLowerCase()}` || "#"}
																				key={cat.id}>
																				<span
																					className="list-group-item list-group-item-action"
																					key={cat.id}
																					onMouseOver={() => updateCategoryType(cat.id)}>
																					{cat.name}
																				</span>
																			</Link>
																		);
																	})}
															</div>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Type</h4>
															<div
																className="tab-content"
																id="nav-tabContent">
																<div
																	className="tab-pane fade show active"
																	id="solar-panel"
																	role="tabpanel"
																	aria-labelledby="list-home-list">
																	<div className="list-group list-group-flush">
																		{subCategory &&
																			subCategory.sub_categories &&
																			subCategory.sub_categories.map((subcat, i) => {
																				return (
																					<Link
																						key={subcat.id}
																						to={
																							`${process.env.PUBLIC_URL}/shop/product/${subCategory.name.replace(/\s+/g, "-").toLowerCase()}/${subcat.name
																								.replace(/\s+/g, "-")
																								.replace(new RegExp("/", "g"), "!")
																								.toLowerCase()}` || "#"
																						}>
																						<span className="list-group-item list-group-item-action">{subcat.name}</span>
																					</Link>
																				);
																			})}
																	</div>
																</div>
																<div
																	className="tab-pane fade"
																	id="list-solar-inverters"
																	role="tabpanel"
																	aria-labelledby="list-solar-inverters-list">
																	Solar Inverters
																</div>
																<div
																	className="tab-pane fade"
																	id="list-solar-batteries"
																	role="tabpanel"
																	aria-labelledby="list-solar-batteries-list">
																	Solar Batteries
																</div>
																<div
																	className="tab-pane fade"
																	id="list-racking-mounts"
																	role="tabpanel"
																	aria-labelledby="list-racking-mounts-list">
																	Racking & Mounts
																</div>
																<div
																	className="tab-pane fade"
																	id="list-pv-cables"
																	role="tabpanel"
																	aria-labelledby="list-pv-cables-list">
																	PV Cables
																</div>
																<div
																	className="tab-pane fade"
																	id="list-complete-pv-systems"
																	role="tabpanel"
																	aria-labelledby="list-complete-pv-systems-list">
																	Complete PV Systems
																</div>
																<div
																	className="tab-pane fade"
																	id="list-pv-connectors"
																	role="tabpanel"
																	aria-labelledby="list-pv-connectors-list">
																	PV Connectors
																</div>
																<div
																	className="tab-pane fade"
																	id="list-components-parts"
																	role="tabpanel"
																	aria-labelledby="list-components-parts-list">
																	Components Parts
																</div>
																<div
																	className="tab-pane fade"
																	id="list-diy-kits-equipment"
																	role="tabpanel"
																	aria-labelledby="list-diy-kits-equipment-list">
																	DIY Kits & Equipment
																</div>
																<div
																	className="tab-pane fade"
																	id="list-solar-lights"
																	role="tabpanel"
																	aria-labelledby="list-solar-lights-list">
																	Solar Lights
																</div>
																<div
																	className="tab-pane fade"
																	id="list-ev-chargers"
																	role="tabpanel"
																	aria-labelledby="list-ev-chargers-list">
																	EV Chargers
																</div>
																<div
																	className="tab-pane fade"
																	id="list-generator"
																	role="tabpanel"
																	aria-labelledby="list-generator-list">
																	Generator
																</div>
																<div
																	className="tab-pane fade"
																	id="list-electrical-equipment"
																	role="tabpanel"
																	aria-labelledby="list-electrical-equipment-list">
																	Electrical Equipment
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/*  Right Section  */}
											<div className="col-4 bg-lighter">
												<div className="nav-content p-5">
													<h4 className="mega-menu-section-title">Featured Storefronts</h4>
													{stores.map((store, index) => {
														return (
															<div
																className="card"
																key={store.profile_images}>
																<div className="card-body">
																	<div className="row">
																		<div className="col-2">
																			<Link to="#">
																				<img
																					alt="storefront_image"
																					src={store.profile_images.length > 0 ? `${IMAGES_BASE_URL}${store.profile_images[0].path}` : `${process.env.PUBLIC_URL}/assets/images/store-user.png`}
																				/>
																			</Link>
																		</div>
																		<div className="col-10">
																			<h5 className="card-title">
																				{store.title ? store.title : store.name}
																				<small className="text-success">
																					<i className="far fa-badge-check"></i> Verified
																				</small>
																			</h5>
																			<h6>{store.subtitle && store.subtitle.length > 50 ? `${store.subtitle.substring(0, 48)}...` : store.subtitle}</h6>
																			<p
																				className="card-text"
																				dangerouslySetInnerHTML={safeContent(store.company_vision ? store.company_vision : "")}
																				style={{
																					overflow: "hidden",
																					textOverflow: "ellipsis",
																					display: "-webkit-box",
																					WebkitLineClamp: "3",
																					lineClamp: "3",
																					WebkitBoxOrient: "vertical",
																				}}></p>
																			{/* <p className="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
																			<Link
																				className=""
																				to={STORE_FRONT_PAGE(store.user_id, `${store.name && store.name}`)}>
																				Shop Storefront <i className="far fa-long-arrow-right"></i>
																			</Link>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
							{/* Sell -- Mega Menu Navigation Block  */}

							<li className="nav-item dropdown">
								<Link
									className="nav-link dropdown-toggle"
									to="#"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Sell
								</Link>
								<div
									className="dropdown-menu megaMenuContainer sellMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="p-5">
											<div className="row">
												<div className="col-lg-6">
													<div className="row h-100 hover-on">
														<div className="col-lg-4">
															<div className="h-100 bg-light w-100">
																<img
																	src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/bulk-sale.jpg`}
																	alt="Bulk Sale"
																	className="w-100 h-100 rounded"
																/>
																<img
																	src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/bulk-sale-hover.jpg`}
																	alt="Bulk Sale Hover"
																	className="w-100 h-100 show-on-hover rounded"
																/>
															</div>
														</div>
														<div className="col-lg-8">
															<div className="card">
																<div className="card-body">
																	<h5 className="text-primary fw-semibold mb-5">Negotiable - Price and Terms</h5>
																	<ul className="trader-feature-list">
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			All Solar Equipment Categories
																		</li>
																		<ul>
																			<li>Solar Panels, Batteries, Inverters & Components</li>
																		</ul>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Minimum Order Quantity Requirements
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Selling Unit - PPW or Quantity
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Inventory Available On Hand or Pre-Production
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Accept Offers
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Negotiable Online
																		</li>
																		<ul>
																			<li>Price</li>
																			<li>Logistics/Incoterms</li>
																			<li>Payment and Delivery Terms</li>
																			<li>Add-on Services</li>
																		</ul>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Contract based Checkout Process
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Offer Negotiation with Seller Anonymity
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Sunhub Moderated and Assisted
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Sunhub Marketing Offerings Available
																		</li>
																	</ul>
																</div>
																<button
																	onClick={goToTraderPlatform}
																	className="btn btn-outline-primary-2 btn-block btn-lg">
																	Go to Solar Trader Platform
																</button>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="row h-100 hover-on">
														<div className="col-lg-4">
															<div className="h-100 bg-light w-100">
																<img
																	src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/unit-sale.jpg`}
																	alt="Unit Sale"
																	className="w-100 h-100 rounded"
																/>
																<img
																	src={`${process.env.PUBLIC_URL}/assets/images/revamp/sell-menu/unit-sale-hover.jpg`}
																	alt="Unit Sale Hover"
																	className="w-100 h-100 show-on-hover rounded"
																/>
															</div>
														</div>
														<div className="col-lg-8">
															<div className="card">
																<div className="card-body">
																	<h5 className="text-primary fw-semibold mb-5">Buy Now - Fixed Price and Terms</h5>
																	<ul className="trader-feature-list">
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			All Solar Equipment Categories
																		</li>
																		<ul>
																			<li>Solar Panels, Batteries, Inverters & Components</li>
																		</ul>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			No Minimum Order Quantity Requirements
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Selling Unit - Quantity
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Inventory Available on Hand
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Fixed Price
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Non Negotiable Terms
																		</li>
																		<ul>
																			<li>Fixed Price</li>
																			<li>Shipping Included or Local Pickup</li>
																			<li>Upfront Payment</li>
																			<li>Support via Sunhub Customer Service</li>
																		</ul>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Cart based Checkout Process
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Named Supplier
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Online E-com Transaction
																		</li>
																		<li>
																			<span className="sunhub-icon">
																				<SunhubIcon />
																			</span>
																			Sunhub Marketing Offerings Available
																		</li>
																	</ul>
																</div>
																<button
																	onClick={goToSellerCentral}
																	className="btn btn-outline-primary-2 btn-block btn-lg">
																	Go To Seller Central
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/*
										==================== Old Navigation Styles ====================
										<div className="p-5">
											<h4 className="mega-menu-section-title-lg">If you are Selling</h4>
											<div className="row">
												<div className="col-5 offset-1">
													<div className="card h-100 mt-3">
														<div className="card-body">
															<img
																className="circuler-img"
																alt="login"
																src={`${process.env.PUBLIC_URL}assets/images/broker/login/icon-on-hover.svg`}
															/>
															<div className="card-title">Solar Trader Platform</div>
															<div className="card-text">
																<ul className="trader-feature-list">
																	<li>Solar Panels PPW</li>
																	<li>Batteries & Inverters</li>
																	<li>Have Negotiable Contract Terms</li>
																	<ul>
																		<li>Incoterms</li>
																		<li>Payment</li>
																		<li>Delivery</li>
																	</ul>
																</ul>
															</div>
															<button
																onClick={goToTraderPlatform}
																className="btn btn-dark btn-block">
																Go to Solar Trader Platform <i className="fal fa-long-arrow-right"></i>
															</button>
														</div>
													</div>
												</div>
												<div className="col-5">
													<div className="card h-100 mt-3">
														<div className="card-body">
															<img
																className="circuler-img"
																alt="seller center"
																src={`${process.env.PUBLIC_URL}assets/images/broker/login/icon-on-hover-1.svg`}
															/>
															<div className="card-title mt-3">Seller Central </div>
															<div className="card-text">
																<ul className="trader-feature-list">
																	<li>Everything else</li>
																	<li>Price per unit</li>
																	<li>Fixed Terms</li>
																	<li>Fixed Price</li>
																</ul>
															</div>
															<button
																onClick={goToSellerCentral}
																className="btn btn-dark btn-block">
																Go To Seller Central <i className="fal fa-long-arrow-right"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</li>

							{/* Solutions -- Mega Menu Navigation Block  */}
							<li className="nav-item dropdown">
								<Link
									className="nav-link dropdown-toggle"
									to="#"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Solutions
								</Link>
								<div
									className="dropdown-menu megaMenuContainer solutionMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="row">
											<div className="col-9 feature-section-content">
												<div className="p-5">
													<h4 className="mega-menu-section-title-lg">Our Solutions</h4>
													<div className="row mt-5 mb-5 ">
														<div className="col-4">
															<h4 className="mega-menu-section-title">Equipment Procurement</h4>
															<p>Simplified buying and selling of a wide selection of available inventory.</p>
															<p>
																<Link to="/solutions/equipment-procurement">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Logistics & Warehousing</h4>
															<p>Our in-house team ensures you have everything when and where you need it.</p>
															<p>
																<Link to="/solutions/logistics-warehousing">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Project Planning</h4>
															<p>The insight buyers and sellers need to manufacture and secure inventory.</p>
															<p>
																<Link to="/solutions/project-planning">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
													</div>
													<div className="row">
														<div className="col-4">
															<h4 className="mega-menu-section-title">Consulting</h4>
															<p>Working as an extension of your team to do the homework and find answers.</p>
															<p>
																<Link to="/solutions/consulting">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Contract Negotiation & Generation</h4>
															<p>Easy quoting, bidding and buying—all in one platform.</p>
															<p>
																<Link to="/solutions/contract-negotiation">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">E-Commerce Distribution</h4>
															<p>An easy way to bring your business online.</p>
															<p>
																<Link to="/solutions/e-commerce-distribution">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
													</div>
												</div>
											</div>
											{/*  Right Section  */}
											<div className="col-3 ">
												<div className="feature-solution">
													<div className="feature-card">
														<h4 className="mega-menu-section-title text-white">Featured Solution</h4>
														<p className="text-white mb-3 ">An easy way to bring your business online.</p>
														<p className="">
															<Link
																to="/solutions"
																className={"btn btn-dark btn-round"}>
																Read Case Study
															</Link>
														</p>
													</div>

													<img
														className="w-100"
														src={`${process.env.PUBLIC_URL}/assets/images/revamp/nav-feature-img.png`}
														alt="man installing panel on roof"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>

							{/* Solutions -- Mega Menu Navigation Block  */}
							<li className="nav-item dropdown">
								<Link
									className="nav-link dropdown-toggle"
									to="/industries"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Industries
								</Link>
								<div
									className="dropdown-menu megaMenuContainer industriesMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="row">
											<div className="col-9 feature-section-content">
												<div className="p-5">
													<h4 className="mega-menu-section-title-lg">Industries We Solve For</h4>
													<div className="row mt-5 mb-5 ">
														<div className="col-4">
															<h4 className="mega-menu-section-title">Residential Installers</h4>
															<p>Simplified buying and selling of a wide selection of available inventory.</p>
															<p>
																<Link to="/industries/residential">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Commercial Installers</h4>
															<p>Our in-house team ensures you have everything when and where you need it.</p>
															<p>
																<Link to="/industries/commercial">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Utility</h4>
															<p>The insight buyers and sellers need to manufacture and secure inventory.</p>
															<p>
																<Link to="/industries/utility">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
													</div>
													<div className="row">
														<div className="col-4">
															<h4 className="mega-menu-section-title">EPC’s</h4>
															<p>Working as an extension of your team to do the homework and find answers.</p>
															<p>
																<Link to="/industries/epc">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Manufacturers</h4>
															<p>Easy quoting, bidding and buying—all in one platform.</p>
															<p>
																<Link to="/industries/manufacturers">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
														<div className="col-4">
															<h4 className="mega-menu-section-title">Distributors</h4>
															<p>An easy way to bring your business online.</p>
															<p>
																<Link to="/industries/distributors">
																	Learn More <i className="fal fa-long-arrow-right"></i>
																</Link>
															</p>
														</div>
													</div>
												</div>
											</div>
											{/*  Right Section  */}
											<div className="col-3 ">
												<div className="feature-solution">
													<div className="feature-card">
														<h4 className="mega-menu-section-title text-white">Featured Solution</h4>
														<p className="text-white mb-3 ">An easy way to bring your business online.</p>
														<p className="">
															<Link
																to="/industries"
																className={"btn btn-dark btn-round"}>
																Read Case Study
															</Link>
														</p>
													</div>

													<img
														className="w-100"
														src={`${process.env.PUBLIC_URL}/assets/images/revamp/case-study-industries.png`}
														alt="installer holding a laptop"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
							{/* Industries -- Mega Menu Navigation Block  */}
							<li className="nav-item dropdown">
								<Link
									className="nav-link dropdown-toggle"
									to="#"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Company
								</Link>
								<div
									className="dropdown-menu megaMenuContainer companyMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="feature-section-content p-5">
											<div className="row">
												<div className="col-12 mb-5">
													<h4 className="mega-menu-section-title-lg">Our Company</h4>
												</div>

												<div className="col-4">
													<h4 className="mega-menu-section-title">About Sunhub</h4>

													<ul className="list-group list-group-flush mt-3">
														<Link
															className=""
															to={`${process.env.PUBLIC_URL}/about-sunhub`}>
															<li className="list-group-item list-group-item-action">Our story</li>
														</Link>
														<Link
															className=""
															to={`${process.env.PUBLIC_URL}/contact-us`}>
															<li className="list-group-item list-group-item-action">Contact Us</li>
														</Link>
													</ul>
												</div>
												<div className="col-4">
													<h4 className="mega-menu-section-title">News & Insights</h4>
													<ul className="list-group list-group-flush mt-3">
														<Link
															className=""
															to={`${process.env.PUBLIC_URL}/events`}>
															<li className="list-group-item list-group-item-action">Events</li>
														</Link>
													</ul>
												</div>

												<div className="col-4">
													<div className="card px-5 py-3 bg-lighter">
														<div className="card-body">
															<div className="card-title">Still have questions? Call Us!</div>
															<div className="card-text">Get in touch with our 24/7 Sunhub support team.</div>
															<h4 className="mega-menu-section-title-lg">
																<a
																	href="tel:2168009300"
																	style={{ textDecoration: "none", color: "inherit" }}>
																	216-800-9300
																</a>
															</h4>
														</div>
													</div>
												</div>
											</div>
											{/*  Right Section  */}
										</div>
									</div>
								</div>
							</li>

							{/* Company -- Mega Menu Navigation Block  */}
							<li className="nav-item dropdown">
								<Link
									className="nav-link dropdown-toggle"
									to="#"
									id="megamenuDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									Resources
								</Link>
								<div
									className="dropdown-menu megaMenuContainer companyMegaMenu"
									aria-labelledby="megamenuDropdown">
									<div className="container-fluid w-100 p-0 m-0">
										<div className="feature-section-content p-5">
											<div className="row">
												<div className="col-12 mb-5">
													<h4 className="mega-menu-section-title-lg">Our Resources</h4>
												</div>

												<div className="col-3">
													<h4 className="mega-menu-section-title">Education & Training</h4>

													<ul className="list-group list-group-flush mt-3">
														<a
															href={`${process.env.PUBLIC_URL}/blog/`}
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: "none", color: "inherit" }} // Use inline styles to remove default styles
														>
															<li className="list-group-item list-group-item-action">Blog</li>
														</a>
														<Link to={`${process.env.PUBLIC_URL}/trader/how-to-buy`}>
															<li className="list-group-item list-group-item-action">Buyer Trader Guide</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/trader/how-to-sell`}>
															<li className="list-group-item list-group-item-action">Seller Trader Guide</li>
														</Link>
													</ul>
												</div>
												<div className="col-2">
													<h4 className="mega-menu-section-title">Community</h4>
													<ul className="list-group list-group-flush mt-3">
														<a
															href="https://forum.sunhub.com/"
															target="_blank"
															rel="noopener noreferrer"
															style={{ textDecoration: "none", color: "inherit" }} // Use inline styles to remove default styles
														>
															<li className="list-group-item list-group-item-action">Forum</li>
														</a>
														<Link to={`${process.env.PUBLIC_URL}/custom-order-fulfillment`}>
															<li className="list-group-item list-group-item-action">Request Inventory</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/custom-order-listing`}>
															<li className="list-group-item list-group-item-action">Custom Order Listing</li>
														</Link>
													</ul>
												</div>

												<div className="col-3">
													<h4 className="mega-menu-section-title">Suppoort</h4>
													<ul className="list-group list-group-flush mt-3">
														<Link to={`${process.env.PUBLIC_URL}/order-status`}>
															<li className="list-group-item list-group-item-action">Order Status</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/faq`}>
															<li className="list-group-item list-group-item-action">FAQ's</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/rv-quotation`}>
															<li className="list-group-item list-group-item-action">RV Quotation</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/storefront-listing`}>
															<li className="list-group-item list-group-item-action">StoreFront Listing</li>
														</Link>
														<Link to={`${process.env.PUBLIC_URL}/brands`}>
															<li className="list-group-item list-group-item-action">Brands</li>
														</Link>
													</ul>
												</div>

												<div className="col-4">
													<div className="card px-5 py-3 bg-lighter">
														<div className="card-body">
															<div className="card-title">Still have questions? Call Us!</div>
															<div className="card-text">Get in touch with our 24/7 Sunhub support team.</div>
															<h4 className="mega-menu-section-title-lg">
																<a
																	href="tel:2168009300"
																	style={{ textDecoration: "none", color: "inherit" }}>
																	216-800-9300
																</a>
															</h4>
														</div>
													</div>
												</div>
											</div>
											{/*  Right Section  */}
										</div>
									</div>
								</div>
							</li>

							{advertiseButton && (
								<li className="nav-item">
									<a
										// onClick={goToMarketing}
										href="/marketing"
										className="btn btn-outline-primary-2 btn-round">
										Advertise with us
									</a>
								</li>
							)}
						</ul>
					</div>

					<ul className="list-inline ml-auto mb-0 capsule-nav-indicator">
						<li className="list-inline-item">
							<div className="dropdown sunhub-contact-dropdown h-100">
								<Link
									to={`${process.env.PUBLIC_URL}/#.`}
									className=" d-flex align-items-center h-100">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/revamp/phone-icon.svg`}
										alt="phone-icon"
									/>
									<div className="dropdown-menu dropdown-menu-right text-center">
										<div className="px-5 py-4 bg-light-2">
											<div className="dd-title">Have Questions? We have answers!</div>
											<a
												className="call-us"
												href="tel:216-800-9300">
												(216) 800-9300
											</a>
										</div>
										<div className="dropdown-footer p-0">
											<Link
												to={`${process.env.PUBLIC_URL}/contact-us`}
												className="btn btn-white btn-block btn-lg rounded-0">
												Contact Us
											</Link>
										</div>
									</div>
								</Link>
							</div>
						</li>
						<li className="list-inline-item">
							<div className="dropdown user-auth-dropdown">
								{isLoggedIn && (
									<>
										<div className="my-account-dropdown">
											<ul className="dropdown-menu">
												<li className="menu-title">
													<small>Welcome</small>
													{user.user && user.user.firstname} {user.user && user.user.lastname}
												</li>
												<li className="dropdown-item">
													{userData && userData.userData.data && userData.userData.data.role && userData.userData.data.role.includes("seller") ? (
														<Link to={`${process.env.PUBLIC_URL}/my-sunhub/seller-overview/sales-snapshot`}>
															<span className="far fa-person-dolly-empty"></span>
															My Store
														</Link>
													) : null}
												</li>
												<li className="dropdown-item">
													<Link to={`${process.env.PUBLIC_URL}/my-sunhub/buying-overview/purchase-history`}>
														<span className="far fa-shopping-basket"></span>My Purchases
													</Link>
												</li>
												{userData && role && role.includes("customer") && !(userData && role.includes("seller")) && (
													<li className="dropdown-item">
														<Link to={`${process.env.PUBLIC_URL}/my-sunhub/buying-overview/purchase-history`}>
															<span className="far fa-cash-register"></span>
															My Trader Purchases
														</Link>
													</li>
												)}
												<li className="dropdown-item">
													{userData && userData.userData.data && userData.userData.data.role && userData.userData.data.role.includes("seller") ? (
														<Link to={`${process.env.PUBLIC_URL}/trader/deals-listing`}>
															<span className="far fa-clipboard-list"></span>
															My Trader Listings
														</Link>
													) : null}
												</li>
												{userData && role && role.includes("customer") && (
													<li className="dropdown-item">
														<Link to={`${process.env.PUBLIC_URL}/trader/chat/buyer`}>
															<span className="far fa-comments-alt"></span>
															My Trader Bids
														</Link>
													</li>
												)}
												<li className="dropdown-item">
													{userData && userData.userData.data && userData.userData.data.role && userData.userData.data.role.includes("seller") ? (
														<Link to={`${process.env.PUBLIC_URL}/trader/chat/seller`}>
															<span className="far fa-comments-alt"></span>
															My Trader Offers
														</Link>
													) : null}
												</li>

												<li className="dropdown-item">
													<Link to={`${process.env.PUBLIC_URL}/my-sunhub/user-setting`}>
														<span className="far fa-cog"></span>
														My Account
													</Link>
												</li>
												{userData && userData.userData.data && userData.userData.data.role && userData.userData.data.role.includes("seller") && (
													<li className="dropdown-item">
														<Link to={STORE_FRONT_PAGE(userData.userData.data.user.id, `${user.user && user.user.firstname}`)}>
															<i className="far fa-store-alt"></i>
															My Storefront
														</Link>
													</li>
												)}

												{userData && userData.userData.data && userData.userData.data.role && (userData.userData.data.role.includes("seller") || userData.userData.data.role.includes("customer")) && (
													<li className="dropdown-item">
														<Link to={`${process.env.PUBLIC_URL}/shop/wishlist`}>
															<span className="far fa-heart"></span>
															Wishlist
														</Link>
													</li>
												)}

												<li className="dropdown-item">
													<Link
														to="#"
														onClick={() => signout(user.auth && user.auth.access_token, "/")}
														className="static-item">
														<span className="far fa-sign-out-alt"></span>
														Sign Out
													</Link>
												</li>
											</ul>
										</div>
									</>
								)}
								{!isLoggedIn && (
									<Link to={`${process.env.PUBLIC_URL}/#.`}>
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/user-profile.svg`}
											className="d-flex align-items-center h-100"
											alt="user-profile-without-login"
										/>
									</Link>
								)}
								{isLoggedIn && (
									<Link to="">
										<img
											src={`${process.env.PUBLIC_URL}/assets/images/revamp/user-profile.svg`}
											className="d-flex align-items-center h-100"
											alt="user-profile-icon-withLogin"
										/>
									</Link>
								)}

								{!isLoggedIn && (
									<div className="dropdown-menu dropdown-menu-right">
										<Link
											to={`${process.env.PUBLIC_URL}/login`}
											className="dropdown-item">
											<i className="far fa-user"></i> Sign in
										</Link>
										<Link
											to={`${process.env.PUBLIC_URL}/register`}
											className="dropdown-item">
											<i className="far fa-user-unlock"></i> Sign Up
										</Link>
									</div>
								)}
							</div>
						</li>
						<li className="list-inline-item">
							<CartMenuRevamp capsuleNavigationCart={true} />
						</li>
					</ul>

					<ul className="display-cta-block list-inline ml-auto mb-0">
						<li className="list-inline-item mr-2">
							<Button
								onClick={goToTrader}
								className="btn btn-outline-primary-2 btn-round"
								size="large">
								<i className="far fa-solar-panel"></i> Solar Trader Platform
							</Button>
						</li>
						<li className="list-inline-item">
							<Button
								onClick={() => setIsLoginModalVisible(true)}
								className="btn btn-outline-primary-2 btn-round"
								size="large">
								<i className="far fa-user-hard-hat"></i> Sell on Sunhub
							</Button>
						</li>
					</ul>
				</div>
			</nav>
			<LoginModal
				userData={userData}
				loginModalVisible={loginModalVisible}
				setIsLoginModalVisible={setIsLoginModalVisible}
			/>
		</>
	);
};
export const mapStateToProps = (state) => {
	return {
		categories: state.categories.categories ? state.categories.categories : [],
		brands: state.brands ? (state.brands.brands ? state.brands.brands : []) : [],
		userData: state.userData ? state.userData : [],
	};
};

export default connect(mapStateToProps, null)(MegaMenuItems);
